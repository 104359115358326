import { useContext, useEffect, useState } from "react";
import { Outlet, NavLink } from "react-router-dom";
import cokelogo from "../../assets/coca-cola-logo.svg";
import navigationButton from "../../assets/navigation_icon.svg";
import ClickAwayListener from "react-click-away-listener";
import styles from "./Layout.module.css";
import { AuthenticatedTemplate, useMsalAuthentication } from "@azure/msal-react";
import { Icon, Stack } from "@fluentui/react";
import { InteractionType } from "@azure/msal-browser";
import { useMsal, useAccount } from "@azure/msal-react";
import { appInsights } from "../../AppInsights";
import { GlobalContext } from "../../App";

const Layout = () => {
    const globalContext = useContext(GlobalContext);
    const { login, result, error } = useMsalAuthentication(InteractionType.Redirect);

    const { instance, accounts } = useMsal();
    const [activeAccount, setActiveAccount] = useState(useAccount(accounts[0])); // TODO should probably just be a variable, not a state effect
    const domainText = ["hr", "HR"].includes(globalContext.domain) ? "" : `- ${globalContext.domain.toUpperCase()}`;

    // TODO probably not a good thing to be doing in an interval
    useEffect(() => {
        setTimeout(() => appInsights.setAuthenticatedUserContext(accounts[0]?.name!, accounts[0]?.tenantId, true), 3000);
    }, []);

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    function SignOut(): void {
        instance.logoutRedirect({
            account: activeAccount
        });
    }

    return (
        <AuthenticatedTemplate>
            <div className={styles.layout}>
                <header className={styles.header} role="banner">
                    <Stack horizontal className={styles.menuButtonStack}>
                        <div className={styles.menuButton}>
                            <img
                                title="Navigation Menu"
                                src={navigationButton}
                                role="button"
                                alt="Navigation Menu"
                                onClick={toggleMenu}
                                aria-label="Navigation Menu"
                            />
                        </div>
                        <span className={styles.userAccountLabel} id="username" title={accounts[0]?.username}>
                            Welcome, {accounts[0]?.name}
                        </span>
                    </Stack>
                    <div className={styles.headerContainer}>
                        <div className={styles.headerAlignment}>
                            <img src={cokelogo} alt="Coca-Cola" />{" "}
                            <span className={styles.headerTitleContainerInnerSpan}>
                                <span className={styles.headerSpanSpacer}>&nbsp; | &nbsp;&nbsp;</span>Knowledge Hub {domainText}
                            </span>
                        </div>
                    </div>
                    <div className={styles.navigation}>
                        <nav className={styles.headerNavContainer}>
                            <ul className={styles.headerNavList}>
                                <li>
                                    <NavLink
                                        aria-label="Chat (Navigation)"
                                        to="/chat"
                                        className={({ isActive }) => (isActive ? styles.headerNavPageLinkActive : styles.headerNavPageLink)}
                                    >
                                        Chat
                                    </NavLink>
                                </li>
                                <li className={styles.headerNavLeftMargin}>
                                    <NavLink
                                        aria-label="Ask a question (Navigation)"
                                        to="/qa"
                                        className={({ isActive }) => (isActive ? styles.headerNavPageLinkActive : styles.headerNavPageLink)}
                                    >
                                        Ask a question
                                    </NavLink>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </header>
                <Outlet />
            </div>
            {isMenuOpen && (
                <ClickAwayListener onClickAway={() => setIsMenuOpen(false)}>
                    <div className={styles.navMenu}>
                        <ul>
                            <li>
                                <NavLink
                                    aria-label="FAQs"
                                    target="_blank"
                                    to="https://groups.coca-cola.com/sites/digital-workplace/SitePages/Coca-Cola-and-ChatGPT.aspx#faqs"
                                    onClick={toggleMenu}
                                >
                                    FAQs
                                </NavLink>
                            </li>
                            <li>
                                <NavLink aria-label="Need Help?" to="mailto:chatgpt@coca-cola.com?subject=Knowledge Hub help!" onClick={toggleMenu}>
                                    Need Help?
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    aria-label="Have a question?"
                                    target="_blank"
                                    to="https://web.yammer.com/main/groups/eyJfdHlwZSI6Ikdyb3VwIiwiaWQiOiI3NzMyNzY0NjcyIn0/all"
                                    onClick={toggleMenu}
                                >
                                    Have a question?
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    aria-label="Policies"
                                    target="_blank"
                                    to="https://groups.coca-cola.com/sites/policies-and-procedures/SitePages/Global/Acceptable-Use-Policy.aspx"
                                    onClick={toggleMenu}
                                >
                                    Policies
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="" onClick={SignOut}>
                                    <Icon iconName="SignOut" aria-hidden={true} /> Logout
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </ClickAwayListener>
            )}
        </AuthenticatedTemplate>
    );
};

export default Layout;
