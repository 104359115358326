import { createContext, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";

import Layout from "./pages/layout/Layout";
import NoPage from "./pages/NoPage";
import OneShot from "./pages/oneshot/OneShot";
import Chat from "./pages/chat/Chat";
import { LoginPage } from "./pages/login/Login";

import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./AppInsights";

// MSAL imports
import { MsalProvider } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";
import { CustomNavigationClient } from "./utils/NavigationClient";

// Fluent UI imports
import { initializeIcons } from "@fluentui/font-icons-mdl2";

type GlobalContextType = {
    domain: string;
    setDomain: (domain: string) => void;
};

const GlobalContext = createContext<GlobalContextType>({ domain: 'HR', setDomain: () => {} });

type AppProps = {
    pca: IPublicClientApplication;
};

function App({ pca }: AppProps) {
    // The next 3 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
    const navigate = useNavigate();
    const navigationClient = new CustomNavigationClient(navigate);
    pca.setNavigationClient(navigationClient);

    initializeIcons();

    return (
        <MsalProvider instance={pca}>
            <Pages />
        </MsalProvider>
    );
}

function Pages() {
    const [domain, setDomain] = useState('HR');

    return (
        <AppInsightsContext.Provider value={reactPlugin}>
            <GlobalContext.Provider value={{ domain, setDomain }}>
                <Routes>
                    <Route index element={<LoginPage />} />
                    <Route path="/" element={<Layout />}>
                        <Route index element={<Chat />} />
                        <Route path="chat" element={<Chat />} />
                        <Route path="qa" element={<OneShot />} />
                        <Route path="*" element={<NoPage />} />
                    </Route>
                </Routes>
            </GlobalContext.Provider>
        </AppInsightsContext.Provider>
    );
}

export { App, GlobalContext };
