import styles from "./CokeButton.module.css";

interface Props {
    buttonText: string;
    onClick: () => void;
    disabled?: boolean;
}

export const CokeButton = ({ buttonText, disabled, onClick }: Props) => {
    return (
        <button type="button" className={styles.CokeButton} onClick={onClick} disabled={disabled}>
            {buttonText}
        </button>
    );
};
