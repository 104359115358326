import { Example } from "./Example";

import styles from "./Example.module.css";

export type ExampleModel = {
    text: string;
    value: string;
    link?: string;
    isStatic: boolean;
    background?: string;
};

const ASK_EXAMPLES: ExampleModel[] = [
    {
        text: "What are the 5 pillars of RGM?",
        value: "What are the 5 pillars of RGM?",
        isStatic: false
    },
    {
        text: "Condenses lengthy articles and documents to a summary",
        value: "Condenses lengthy articles and documents to a summary",
        isStatic: true
    },
    {
        text: "Personal or location data is not used to provide customized responses.",
        value: "Personal or location data is not used to provide customized responses.",
        isStatic: true
    },
    {
        text: "How do I request a VOE/ VISA letter?",
        value: "How do I request a VOE/ VISA letter?",
        isStatic: false
    },
    {
        text: "What's New?",
        value: "What's New?",
        link: "https://groups.coca-cola.com/sites/digital-workplace/SitePages/Coca-Cola-and-ChatGPT1.aspx",
        isStatic: true,
        background: "#ddd"
    },
    {
        text: "Limited to Coca-Cola HR Policies, Benefits, Workday and RGM Topics",
        value: "Limited to Coca-Cola HR Policies, Benefits, Workday and RGM Topics",
        isStatic: true
    },
    {
        text: "How to identify channels for Coca-Cola revenue growth?",
        value: "How to identify channels for Coca-Cola revenue growth?",
        isStatic: false
    },
    {
        text: "FAQs",
        value: "FAQs",
        link: "https://groups.coca-cola.com/sites/digital-workplace/SitePages/Coca-Cola-and-ChatGPT.aspx#faqs",
        isStatic: true
    },
    {
        text: "May Occasionally generate incorrect information.",
        value: "May Occasionally generate incorrect information.",
        isStatic: true
    }
];

const EXAMPLES: ExampleModel[] = [
    {
        text: "What is RGM OBBPC?",
        value: "What is RGM OBBPC?",
        isStatic: false
    },
    {
        text: "Remembers what a user said earlier in the conversation",
        value: "Remembers what a user said earlier in the conversation",
        isStatic: true
    },
    {
        text: "Personal or location data is not used to provide customized responses.",
        value: "Personal or location data is not used to provide customized responses.",
        isStatic: true
    },
    {
        text: "How to create Coca-Cola unique product portfolio?",
        value: "How to create Coca-Cola unique product portfolio?",
        isStatic: false
    },
    {
        text: "What's New?",
        value: "What's New?",
        link: "https://groups.coca-cola.com/sites/digital-workplace/SitePages/Coca-Cola-and-ChatGPT1.aspx",
        isStatic: true,
        background: "#ddd"
    },
    {
        text: "Limited to Coca-Cola HR Policies, Benefits, Workday and RGM Topics",
        value: "Limited to Coca-Cola HR Policies, Benefits, Workday and RGM Topics",
        isStatic: true
    },
    {
        text: "What is the vacation policy for employees in the United States?",
        value: "What is the vacation policy for employees in the United States?",
        isStatic: false
    },
    {
        text: "Understands Human language and Context",
        value: "Understands Human language and Context",
        isStatic: true
    },
    {
        text: "May Occasionally generate incorrect information.",
        value: "May Occasionally generate incorrect information.",
        isStatic: true
    }
];

interface Props {
    onExampleClicked: (value: string) => void;
    isChat: boolean;
}

export const ExampleList = ({ onExampleClicked, isChat }: Props) => {
    if (isChat) {
        return (
            <ul className="examplesNavList">
                {EXAMPLES.map((x, i) => (
                    <li key={i}>
                        <Example
                            text={x.text}
                            value={x.value}
                            link={x.link!}
                            isStatic={x.isStatic}
                            index={i}
                            background={x.background!}
                            onClick={onExampleClicked}
                        />
                    </li>
                ))}
            </ul>
        );
    } else {
        return (
            <ul className="examplesNavList">
                {ASK_EXAMPLES.map((x, i) => (
                    <li key={i}>
                        <Example
                            text={x.text}
                            value={x.value}
                            link={x.link!}
                            isStatic={x.isStatic!}
                            index={i}
                            background={x.background!}
                            onClick={onExampleClicked}
                        />
                    </li>
                ))}
            </ul>
        );
    }
};
