import React from "react";
import { Icon } from "@fluentui/react/lib/Icon";
import sparkImg from "../../assets/sparkle.png";
import "./ExampleHeader.styles.css";

const ExampleHeader = () => {
    return (
        <div className="example-header">
            <div>
                <Icon iconName="Lightbulb" style={{ fontSize: "24px", color: "#212121" }} />
                <h3>Examples</h3>
            </div>
            <div>
                <img alt="Sparkle graphic" src={sparkImg} style={{ height: "24px" }} />
                <h3>Capabilities</h3>
            </div>
            <div>
                <Icon iconName="Warning" style={{ fontSize: "24px", color: "#212121" }} />
                <h3>Limitations</h3>
            </div>
        </div>
    );
};

export default ExampleHeader;
