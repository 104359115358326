import { Configuration, PopupRequest } from "@azure/msal-browser";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {
        clientId: "1487193e-6ea8-4c4f-a202-7637ac8ef0cf",
        authority: "https://login.microsoftonline.com/common",
        redirectUri: "/",
        postLogoutRedirectUri: "/"
    }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
    scopes: ["api://1487193e-6ea8-4c4f-a202-7637ac8ef0cf/Service.Login"]
};

// Add here the endpoints for MS Graph API services you would like to use.
export const kbConfig = {
    chatEndpoint: "/api/chat"
};
