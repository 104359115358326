import { Stack } from "@fluentui/react";
import { CokeButton } from "../../components/CokeButton/CokeButton";
import { useNavigate } from "react-router";

import cokeDots from "../../assets/coke-dots.svg";
import cokelogo from "../../assets/coca-cola-logo.svg";

import styles from "./Login.module.css";

interface Props {}

export const LoginPage = ({}: Props) => {
    const navigate = useNavigate();
    const login = () => {
        navigate("/qa");
    };

    return (
        <div className={styles.loginScreen}>
            <div>
                <div className={styles.cokeLogo}>
                    <img src={cokelogo} alt="Coca Cola Company logo" />
                </div>
                <h1>Knowledge Hub</h1>
                <h2>Try The Coca-Cola Company Knowledge Hub to ask questions or chat with our data.</h2>
                <Stack horizontal tokens={{ childrenGap: 20 }}>
                    <img src={cokeDots} alt="dots" />
                    <p>This image was generated using Azure OpenAi and Chat GPT</p>
                </Stack>
                <div className={styles.cokeButton}>
                    <CokeButton buttonText="Try it now" onClick={login} />
                </div>
            </div>
            <div></div>
        </div>
    );
};
