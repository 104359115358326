import React, { useRef, useState, useContext, Suspense } from "react";
import { Checkbox, ChoiceGroup, IChoiceGroupOption, Panel, DefaultButton, Spinner, TextField, SpinButton, Stack } from "@fluentui/react";
import cokeDotsImg from "../../assets/bubbles.png";

import styles from "./OneShot.module.css";

import { askApi, Approaches, AskResponse, AskRequest } from "../../api";
import { ExampleList } from "../../components/Example";
import { AnalysisPanel, AnalysisPanelTabs } from "../../components/AnalysisPanel";
import { SettingsButton } from "../../components/SettingsButton/SettingsButton";
import { QuestionInput } from "../../components/QuestionInput";
import { AnswerError } from '../../components/Domain/Common/AnswerError';
import ExampleHeader from "../../components/ExampleHeader/ExampleHeader";
import { GlobalContext } from "../../App";

import openFolder from "../../assets/open_folder.png";

const OneShot = () => {
    const globalContext = useContext(GlobalContext); // TODO move context into its own component
    const [isConfigPanelOpen, setIsConfigPanelOpen] = useState(false);
    const [approach, setApproach] = useState<Approaches>(Approaches.RetrieveThenRead);
    const [promptTemplate, setPromptTemplate] = useState<string>("");
    const [promptTemplatePrefix, setPromptTemplatePrefix] = useState<string>("");
    const [promptTemplateSuffix, setPromptTemplateSuffix] = useState<string>("");
    const [retrieveCount, setRetrieveCount] = useState<number>(5);
    const [useSemanticRanker, setUseSemanticRanker] = useState<boolean>(true);
    const [useSemanticCaptions, setUseSemanticCaptions] = useState<boolean>(false);
    const [excludeCategory, setExcludeCategory] = useState<string>("");
    const lastQuestionRef = useRef<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<unknown>();
    const [answer, setAnswer] = useState<AskResponse>();
    const [question, setQuestion] = useState<string>(); // TODO should this be park of AskResponse type?
    const [activeCitation, setActiveCitation] = useState<string>();
    const [activeAnalysisPanelTab, setActiveAnalysisPanelTab] = useState<AnalysisPanelTabs | undefined>(undefined);
    // Vite needs explicit path for dynamic imports
    const Answer = React.lazy(() => import(`../../components/Domain/${globalContext.domain.toUpperCase()}/Answer/index.tsx`).then(module => ({ default: module.Answer })));

    const makeApiRequest = async (questionKey: string, domain?: string) => {
        lastQuestionRef.current = questionKey;

        error && setError(undefined);
        setIsLoading(true);
        setActiveCitation(undefined);
        setActiveAnalysisPanelTab(undefined);

        try {
            const request: AskRequest = {
                domain: domain ? domain.toUpperCase() : 'HR',
                questionKey,
                approach,
                overrides: {
                    promptTemplate: promptTemplate.length === 0 ? undefined : promptTemplate,
                    promptTemplatePrefix: promptTemplatePrefix.length === 0 ? undefined : promptTemplatePrefix,
                    promptTemplateSuffix: promptTemplateSuffix.length === 0 ? undefined : promptTemplateSuffix,
                    excludeCategory: excludeCategory.length === 0 ? undefined : excludeCategory,
                    top: retrieveCount,
                    semanticRanker: useSemanticRanker,
                    semanticCaptions: useSemanticCaptions
                }
            };
            const result = await askApi(request);
            setAnswer(result);
            setQuestion(questionKey);
        } catch (e) {
            setError(e);
        } finally {
            setIsLoading(false);
        }
    };

    const onPromptTemplateChange = (_ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setPromptTemplate(newValue || "");
    };

    const onPromptTemplatePrefixChange = (_ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setPromptTemplatePrefix(newValue || "");
    };

    const onPromptTemplateSuffixChange = (_ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setPromptTemplateSuffix(newValue || "");
    };

    const onRetrieveCountChange = (_ev?: React.SyntheticEvent<HTMLElement, Event>, newValue?: string) => {
        setRetrieveCount(parseInt(newValue || "3"));
    };

    const onApproachChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption) => {
        setApproach((option?.key as Approaches) || Approaches.RetrieveThenRead);
    };

    const onUseSemanticRankerChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseSemanticRanker(!!checked);
    };

    const onUseSemanticCaptionsChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseSemanticCaptions(!!checked);
    };

    const onExcludeCategoryChanged = (_ev?: React.FormEvent, newValue?: string) => {
        setExcludeCategory(newValue || "");
    };

    const onExampleClicked = (example: string) => {
        makeApiRequest(example);
    };

    const onShowCitation = (citation: string) => {
        if (activeCitation === citation && activeAnalysisPanelTab === AnalysisPanelTabs.CitationTab) {
            setActiveAnalysisPanelTab(undefined);
        } else {
            setActiveCitation(citation);
            setActiveAnalysisPanelTab(AnalysisPanelTabs.CitationTab);
        }
    };

    const onToggleTab = (tab: AnalysisPanelTabs) => {
        if (activeAnalysisPanelTab === tab) {
            setActiveAnalysisPanelTab(undefined);
        } else {
            setActiveAnalysisPanelTab(tab);
        }
    };

    const approaches: IChoiceGroupOption[] = [
        {
            key: Approaches.RetrieveThenRead,
            text: "Retrieve-Then-Read"
        },
        {
            key: Approaches.ReadRetrieveRead,
            text: "Read-Retrieve-Read"
        },
        {
            key: Approaches.ReadDecomposeAsk,
            text: "Read-Decompose-Ask"
        }
    ];

    return (
        <>
            <div className={styles.oneshotContainer}>
                <div className={styles.oneshotTopSection}>
                    <SettingsButton className={styles.settingsButton} onClick={() => setIsConfigPanelOpen(!isConfigPanelOpen)} />
                    <h1 className={styles.oneshotTitle}>Ask Coca-Cola</h1>
                    <div className={styles.oneshotQuestionInput}>
                        <QuestionInput
                            placeholder="Type your question here (e.g. does my plan cover annual eye exams?)  "
                            disabled={isLoading}
                            onSend={(question: string, domain?: any) => makeApiRequest(question, domain)}
                            showClear={false}
                        />
                    </div>
                </div>
                <div className={styles.oneshotBottomSection}>
                    {isLoading && <Spinner label="Generating answer" />}
                    {!lastQuestionRef.current && (
                        <div>
                            <div className={styles.chatSubHeaderLayout}>
                                <img src={cokeDotsImg} alt="dots" aria-hidden="true" />
                                <h2 className={styles.chatEmptyStateSubtitle}>Ask anything and try out what's new</h2>
                            </div>
                            <ExampleHeader></ExampleHeader>
                            <ExampleList isChat={false} onExampleClicked={onExampleClicked} />
                        </div>
                    )}
                    {!isLoading && answer && !error && (
                        <div className={styles.oneshotAnswerContainer}>
                            <Suspense fallback="Loading...">
                                <Answer
                                    responseData={{ ...answer, question }}
                                    onCitationClicked={(x: any) => onShowCitation(x)}
                                    onThoughtProcessClicked={() => onToggleTab(AnalysisPanelTabs.ThoughtProcessTab)}
                                    onSupportingContentClicked={() => onToggleTab(AnalysisPanelTabs.SupportingContentTab)}
                                />
                            </Suspense>
                        </div>
                    )}
                    {error ? (
                        <div className={styles.oneshotAnswerContainer}>
                            <AnswerError error={error.toString()} onRetry={() => makeApiRequest(lastQuestionRef.current)} />
                        </div>
                    ) : null}
                    {activeAnalysisPanelTab && answer && (
                        <AnalysisPanel
                            className={styles.oneshotAnalysisPanel}
                            activeCitation={activeCitation}
                            onActiveTabChanged={x => onToggleTab(x)}
                            citationHeight="600px"
                            answer={answer}
                            activeTab={activeAnalysisPanelTab}
                        />
                    )}
                </div>

                <Panel
                    headerText="Configure answer generation"
                    isOpen={isConfigPanelOpen}
                    isBlocking={false}
                    onDismiss={() => setIsConfigPanelOpen(false)}
                    closeButtonAriaLabel="Close"
                    onRenderFooterContent={() => <DefaultButton onClick={() => setIsConfigPanelOpen(false)}>Close</DefaultButton>}
                    isFooterAtBottom={true}
                >
                    <ChoiceGroup
                        className={styles.oneshotSettingsSeparator}
                        label="Approach"
                        options={approaches}
                        defaultSelectedKey={approach}
                        onChange={onApproachChange}
                    />

                    {(approach === Approaches.RetrieveThenRead || approach === Approaches.ReadDecomposeAsk) && (
                        <TextField
                            className={styles.oneshotSettingsSeparator}
                            defaultValue={promptTemplate}
                            label="Override prompt template"
                            multiline
                            autoAdjustHeight
                            onChange={onPromptTemplateChange}
                        />
                    )}

                    {approach === Approaches.ReadRetrieveRead && (
                        <>
                            <TextField
                                className={styles.oneshotSettingsSeparator}
                                defaultValue={promptTemplatePrefix}
                                label="Override prompt prefix template"
                                multiline
                                autoAdjustHeight
                                onChange={onPromptTemplatePrefixChange}
                            />
                            <TextField
                                className={styles.oneshotSettingsSeparator}
                                defaultValue={promptTemplateSuffix}
                                label="Override prompt suffix template"
                                multiline
                                autoAdjustHeight
                                onChange={onPromptTemplateSuffixChange}
                            />
                        </>
                    )}

                    <SpinButton
                        className={styles.oneshotSettingsSeparator}
                        label="Retrieve this many documents from search:"
                        min={1}
                        max={50}
                        defaultValue={retrieveCount.toString()}
                        onChange={onRetrieveCountChange}
                    />
                    <TextField className={styles.oneshotSettingsSeparator} label="Exclude category" onChange={onExcludeCategoryChanged} />
                    <Checkbox
                        className={styles.oneshotSettingsSeparator}
                        checked={useSemanticRanker}
                        label="Use semantic ranker for retrieval"
                        onChange={onUseSemanticRankerChange}
                    />
                    <Checkbox
                        className={styles.oneshotSettingsSeparator}
                        checked={useSemanticCaptions}
                        label="Use query-contextual summaries instead of whole documents"
                        onChange={onUseSemanticCaptionsChange}
                        disabled={!useSemanticRanker}
                    />
                </Panel>
            </div>
            <footer className={styles.footer}>
                <Stack horizontal tokens={{ childrenGap: 20 }} verticalAlign="center" horizontalAlign="center">
                    <div>2023 The Coca-Cola Company. All Rights Reserved.</div>
                    <Stack horizontal verticalAlign="center">
                        <a
                            rel="noopener noreferrer"
                            href="https://groups.coca-cola.com/sites/policies-and-procedures/SitePages/Global/Acceptable-Use-Policy.aspx"
                            target="_blank"
                        >
                            Acceptable Use Policy
                        </a>{" "}
                        <img src={openFolder} alt="Open Link" aria-label="Open Link Icon" aria-hidden="true" />
                    </Stack>
                </Stack>
            </footer>
        </>
    );
};

export default OneShot;
