import rightArrow from "../../assets/arrow_right.svg";
import styles from "./Example.module.css";
import { useAppInsightsContext, useTrackMetric } from "@microsoft/applicationinsights-react-js";

interface Props {
    text: string;
    value: string;
    link: string;
    background: string;
    isStatic: boolean;
    index: number;
    onClick: (value: string) => void;
}

export const Example = ({ text, value, link, isStatic, index, onClick, background }: Props) => {
    const appInsights = useAppInsightsContext();
    const trackFAQClick = useTrackMetric(appInsights, "FAQ");
    // const trackMailClick = useTrackMetric(appInsights, "Submit Feedback");
    const trackQuestionClick = useTrackMetric(appInsights, "Question Clicked");
    if (link) {
        return (
            <div
                className={styles.exampleLink}
                style={{ backgroundColor: background }}
                onClick={() => {
                    trackFAQClick(); // TODO this needs to distinguish tracking better
                    window.open(link, "_blank");
                }}
            >
                <p className={styles.exampleText}>
                    {text} <img alt="Arrow Right" className={styles.rightArrow} src={rightArrow} />
                </p>
            </div>
        );
    } else if (isStatic) {
        return (
            <div className={styles.example} style={{ cursor: 'default' }}>
                <p className={styles.exampleText}>{text}</p>
            </div>
        );
    }
    return (
        <div
            className={styles.exampleLink}
            style={{ backgroundColor: background }}
            onClick={() => {
                trackQuestionClick();
                onClick(value);
            }}
        >
            <p className={styles.exampleText}>{text}</p>
        </div>
    );
};
