import { Text } from "@fluentui/react";
import { Settings24Regular } from "@fluentui/react-icons";
import { msalInstance } from "../../index";

import styles from "./SettingsButton.module.css";

interface Props {
    className?: string;
    onClick: () => void;
}

export const SettingsButton = ({ className, onClick }: Props) => {
    const username = msalInstance.getActiveAccount()?.username;
    const validUser = username === "lvangala@coca-cola.com" || username === "pvir@coca-cola.com"; // TODO not sure we should restrict this way
    const showSettings = validUser ? "flex" : "none";
    return (
        <div className={`${styles.container} ${className ?? ""}`} onClick={onClick} style={{ display: `${showSettings}` }}>
            <Settings24Regular />
            <Text>{"Developer settings"}</Text>
        </div>
    );
};
