import { useState, useContext } from "react";
import { Icon, Stack, TextField } from "@fluentui/react";
import { Send28Filled } from "@fluentui/react-icons";

import styles from "./QuestionInput.module.css";
import { GlobalContext } from "../../App";

interface Props {
    onSend: (question: string, domain?: string) => void;
    disabled: boolean;
    placeholder?: string;
    clearOnSend?: boolean;
    showClear?: boolean;
}

// TODO this should probably renamed and return an object with both the context and question
const parseInput = (context: string) => {
    const match: any = context.match(/^((hr|rgm):\/)?(.*)/);
    const domain = match && match[2] ? match[2] : "HR";
    const question = match && match[3] ? match[3] : "";

    return { domain, question };
};

export const QuestionInput = ({ onSend, disabled, placeholder, clearOnSend, showClear }: Props) => {
    const [question, setQuestion] = useState<string>("");
    const globalContext = useContext(GlobalContext);
    const input = parseInput(question);

    const sendQuestion = () => {
        if (disabled || !question.trim()) {
            return;
        }

        onSend(input.question, input.domain);

        if (clearOnSend) {
            setQuestion("");
        }
    };

    const onEnterPress = (ev: React.KeyboardEvent<Element>) => {
        if (ev.key === "Enter" && !ev.shiftKey) {
            ev.preventDefault();
            const context = input.domain;
            globalContext.setDomain(context);
            sendQuestion();
        }
    };

    const onQuestionChange = (_ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (!newValue) {
            setQuestion("");
        } else if (newValue.length <= 1000) {
            setQuestion(newValue);
        }
    };

    const resetChat = () => {
        document.getElementById("clearchat")?.click();
    };

    const sendQuestionDisabled = disabled || !question.trim();

    return (
        <>
            {showClear && (
                <button hidden={showClear} type="button" className={styles.ResetButton} onClick={resetChat}>
                    <Icon iconName="Refresh" /> New Topic
                </button>
            )}
            <Stack horizontal className={styles.questionInputContainer}>
                <TextField
                    className={styles.questionInputTextArea}
                    placeholder={placeholder}
                    multiline={false}
                    resizable={false}
                    borderless
                    value={question}
                    onChange={onQuestionChange}
                    onKeyDown={onEnterPress}
                    style={{ background: "black", color: "white" }} // TODO make dynamic
                />
                <div className={styles.questionInputButtonsContainer}>
                    <div
                        className={`${styles.questionInputSendButton} ${sendQuestionDisabled ? styles.questionInputSendButtonDisabled : ""}`}
                        aria-label="Ask question button"
                        aria-roledescription="button"
                        role="button"
                        onClick={sendQuestion}
                    >
                        <Send28Filled primaryFill="rgba(255, 255, 255, 1)" />
                    </div>
                </div>
            </Stack>
        </>
    );
};
